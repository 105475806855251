
import { useCookies } from '@vueuse/integrations/useCookies'
import { ElMessage } from 'element-plus'

import axios from 'axios'
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: "http://ceshi13.dishait.cn"
    baseURL: "https://api.tgs.plus"
})
export default service;

// 请求拦截器和响应拦截器
// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 拿到token
    const cookies = useCookies()
    const token = cookies.get("token")
    // console.log("token", token);
   
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    response = response
    return response;
}, function (error) {
    // 对响应错误做点什么
    // 所有请求错误时，都会弹出他的错误信息msg
    ElMessage.error(error)
    return Promise.reject(error);
});