import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [{
	path: '/',
	name: 'home',
	component: HomeView,
	meta: {
		title: "主页",
		activeMenu: "/"
	},
}]
const router = createRouter({
	// base: '/zc/',
	history: createWebHashHistory(),
	routes
})

export default router