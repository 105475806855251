<template>
  <div style="width: 100%;height: 100%;" class="bg-blue-500 flex items-center justify-center loginbg">
    <el-card v-if="identificationRegister" style="width: 400px;">
      <div class="centerWindi" style="flex-direction: column; width: 100%;">
        <el-row class="centerWindi">
          <p style="font-size: 18px; font-weight: 600;padding-bottom: 20px;">欢迎注册账号</p>
        </el-row>
        <el-row class="centerWindi wi42" style="width: 300px">
          <el-form class="registerItem" ref="registerRef" :model="registerForm" :rules="registerRules">
            <el-form-item prop="serviceName">
              <el-input v-model="registerForm.serviceName" placeholder="请输入名称" show-word-limit
                maxlength="6"></el-input>
            </el-form-item>
            <el-form-item prop="telephone">
              <el-input v-model="registerForm.telephone" placeholder="请输入手机号"  maxlength="11" show-word-limit ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="registerForm.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="againPassword">
              <el-input v-model="registerForm.againPassword" placeholder="请再次输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="paymentName">
              <el-input v-model="registerForm.paymentName" placeholder="请输入支付宝账户名"></el-input>
            </el-form-item>
            <el-form-item prop="paymentAccount" style="margin-bottom: 6px!important">
              <el-input v-model="registerForm.paymentAccount" placeholder="请输入支付宝账号"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row class="centerWindi" style="padding-top: 11px;margin-bottom: 33px;width: 300px">
          <el-checkbox class="chewi" v-model="checked1" size="large" />
          <span style="font-size: 12px;margin-left: 10px;letterSpacing:1px;color: #B3B3B3">我已阅读并同意<a href=""
              style="color: blue;">《用户协议》</a>和<a href="" style="color: blue;">《隐私政策》</a></span>
        </el-row>
        <el-row class="centerColumn bottonre" style="width: 300px">
          <el-button type="primary" style="width: 100%;" @click="register">注册</el-button>
        </el-row>
        <el-divider style="margin:42px 0 22px 0;width: 300px;">
          <span style="color: #595959;font-size: 14px;">使用微信注册</span>
        </el-divider>
        <el-row class="centerColumn" style="width: 300px;color: #B3B3B3;">
          <span style="cursor: pointer;" @click="vxLogion" class="centerWindi">
            <img style="width: 32px;height: 32px;" src="@/assets/images/wxlogo.png" alt="">
          </span>
        </el-row>
      </div>
    </el-card>
    <el-card v-if="identificationfastRegister" style="width: 400px;">
      <div class="centerWindi" style="flex-direction: column; width: 100%;">
        <el-row class="centerWindi">
          <p style="font-size: 18px; font-weight: 600;padding-bottom: 20px;">绑定账户</p>
        </el-row>
        <el-row class="centerWindi wi42" style="width: 300px;">
          <el-form class="registerItem" ref="registerRef2" :model="registerForm2" :rules="registerRules2">
            <el-form-item prop="serviceName">
              <el-input v-model="registerForm2.serviceName" placeholder="请输入服务商名称" show-word-limit
                maxlength="6"></el-input>
            </el-form-item>
            <el-form-item prop="telephone">
              <el-input v-model="registerForm2.telephone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="registerForm2.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="againPassword">
              <el-input v-model="registerForm2.againPassword" placeholder="请再次输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="paymentName">
              <el-input v-model="registerForm2.paymentName" placeholder="请输入支付宝账户名"></el-input>
            </el-form-item>
            <el-form-item prop="paymentAccount" style="margin-bottom: 6px!important;">
              <el-input v-model="registerForm2.paymentAccount" placeholder="请输入支付宝账号"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <el-row class="centerWindi" style="padding-top: 11px;margin-bottom: 33px;">
          <el-checkbox class="chewi" v-model="checked1" size="large" />
          <span style="font-size: 12px;margin-left: 10px;letterSpacing:1px;color: #B3B3B3">我已阅读并同意<a href=""
              style="color: blue;">《用户协议》</a>和<a href="" style="color: blue;">《隐私政策》</a></span>
        </el-row>
        <el-row class="centerColumn bottonre" style="width: 300px;margin-bottom: 48px;">
          <el-button type="primary" style="width: 100%;" @click="fastRegister">快速注册</el-button>
          <el-button style="width: 100%;margin-left: 0px;margin-top: 10px;" @click="back">返回</el-button>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<style scoped>
.chewi {
  margin-left: 4px;
  /* 放大1.5倍 */
  transform: scale(1.5);
  /* ？？？？？ */
  /* zoom: 180%; */
}

.p1 {
  color: #000000;
  font-size: 17px;
  text-align: center;
  @apply mx-2;
}

.registerItem {
  width: 100%;
}

.registerItem .el-form-item {
  width: 100%;
}

.p2 {
  color: #808080;
  font-size: 12px;
  /* @apply my-2; */
}
</style>
<script setup>
import { ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import { Register } from '@/api/manager.js'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const ID = route.query.id
// 多选框
const checked1 = ref(false)
const checked2 = ref(false)
// 标识
const identificationfastRegister = ref(false)
const identificationRegister = ref(true)
// 注册
ElMessage.error({
  message: "账号注册链接仅供1人注册，有效期为30分钟，请您尽快注册",
  icon: "false",
  // iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
  customClass: 'el-message--error', // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
  duration: 10000,
})
const registerRef = ref()
const registerForm = ref({
  serviceName: "",
  telephone: '',
  password: '',
  againPassword: '',
  paymentName: "",
  paymentAccount: "",
  // serviceName: "上千年",
  // telephone: '18237867346',
  // password: '123456GHJ',
  // againPassword: '123456GHJ',
  // paymentName: "随便",
  // paymentAccount: "2342341",
 })
const registerRules = reactive({
  serviceName: [{
    required: true,//必填
    message: '请输入名称',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  {
    pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
    message: '请不要输入特殊字符',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }],
  telephone: [{
    required: true,//必填
    message: '请输入手机号',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }, {
    required: true,
    pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
    message: '请输入正确的手机号',
    trigger: 'blur'
  }],
  password: [{
    required: true,//必填
    message: '请输入密码',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }, {
    required: true,
    message: '密码必须为8~16位的数字和字母组成',
    pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/,
    trigger: 'blur'
  }],
  againPassword: [{
    required: true,//必填
    message: '请再次输入密码',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  {
    validator: (rule, value, callback) => {
      if (value !== registerForm.value.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }

  }],
  paymentName: {
    required: true,//必填
    message: '请输入支付宝账户名',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  paymentAccount: {
    required: true,//必填
    message: '请输入支付宝账号',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
})
const register = () => {
  console.log('点击注册')
  registerRef.value.validate((valid) => {
    if (valid && checked1.value) {
      // identificationRegister.value = false
      const test = {
        account: registerForm.value.telephone,
        phone: registerForm.value.telephone,
        password: registerForm.value.password,
        payName: registerForm.value.paymentName,
        payNumber: registerForm.value.paymentAccount,
        nickname: registerForm.value.serviceName,
        // avatar: "头像",
        // longitude: "222.222",
        // latitude: "333.333"
      }
      // console.log(test);
      Register(test, ID).then(res => {
        if (res.data.code === 200) {
          ElMessage.success('注册成功,请等待账号审核')
        } else if (res.data.code === 1003) {
          ElMessage.error('该手机号已被注册,请直接登录')
        } else if (res.data.code ===403) {
          ElMessage.error('请携带邀请码或该链接已被注册')
        }
        console.log(res);
      })
      // ElMessage.success('注册成功')
    } else if (!checked1.value) {
      ElMessage.error('请先同意用户协议')
    }
  })
}
// 快速注册
const registerRef2 = ref()
const registerForm2 = ref({
  serviceName: "上千年",
  telephone: '18237867346',
  password: '123456GHJ',
  againPassword: '123456GHJ',
  paymentName: "随便",
  paymentAccount: "2342341",

})
const registerRules2 = reactive({
  serviceName: [{
    required: true,//必填
    message: '请输入服务商名称',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  {
    pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
    message: '请不要输入特殊字符',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }],
  telephone: [{
    required: true,//必填
    message: '请输入手机号',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }, {
    required: true,
    pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
    message: '请输入正确的手机号',
    trigger: 'blur'
  }],
  password: [{
    required: true,//必填
    message: '请输入密码',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  }, {
    required: true,
    message: '密码必须为8~16位的数字和字母组成',
    pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/,
    trigger: 'blur'
  }],
  againPassword: [{
    required: true,//必填
    message: '请再次输入密码',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  {
    validator: (rule, value, callback) => {
      if (value !== registerForm2.value.password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }

  }],
  paymentName: {
    required: true,//必填
    message: '请输入支付宝账户名',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
  paymentAccount: {
    required: true,//必填
    message: '请输入支付宝账号',//验证提示
    trigger: 'blur',//触发时机，这里是失去焦点
  },
})

const fastRegister = () => {
  registerRef2.value.validate((valid) => {
    if (valid && checked2.value) {
      // identificationRegister.value = false
      ElMessage.success('注册成功')
    } else if (!checked2.value) {
      ElMessage.error('请先同意用户协议')
    }
  })
}
// 微信登录
const vxLogion = () => {
  identificationfastRegister.value = true
  identificationRegister.value = false
}
// 返回
const back = () => {
  identificationfastRegister.value = false
  identificationRegister.value = true
}

</script>
<style>
.tabs .el-tabs__item {
  font-size: 20px;
}
</style>